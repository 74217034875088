/*
    C-SKIN EXTENSION
    Extension du composant générique c-skin
    https://github.com/ita-design-system/c-skin.scss
    Ce fichier doit servir à étendre ou surcharger les fonctionnalités
    du composant c-dim selon les besoins du projet
*/
$briks-components-generic: (
    // Nom du composant, obligatoirement skin
    skin: ( 
        // Extension activée true ou false
        enabled: true, 
        // Responsive activée true ou false pour l'extension
        responsive: false, 
        // Valeurs par défaut de l'extension
        defaults: (
            pseudo-classes: (
                // c-skin:disabled
                disabled: (
                    pointer-events: none,
                    color: grey,
                    background-color: lightgrey
                )
            )
        ),
        // Liste des modifieurs contenant chacun une liste de propriétés qui 
        // soit surchargent les propriétés par défaut
        // soit ajoutent des propriétés
        // soit les deux
        modifiers: (
            // PLACEHOLDER
            placeholder-grey-dark-900: (
                pseudo-elements: (
                    placeholder: (
                        color: my-color(grey-dark-900),
                        opacity: 1
                    )
                )
            ),

            // BACKGROUND
            bg-search-left: (
                background: left my-spacing(3) top 50% / my-font-size(6) no-repeat url('../medias/search_line.svg')
            ),
            bg-select: (
                background: right my-spacing(4) top 50% / my-font-size(2) no-repeat my-color(grey-light-100) url('../medias/chevron_down.svg')
            ),

            // c-skin m-color-scheme-1
            color-scheme-1: (
                border-color: my-color(grey-light-500),
                background-color: my-color(grey-light-100),
                pseudo-classes: (
                    hover: (
                        border-color: my-color(grey-dark-900)
                    ),
                    focus: (
                        outline: none,
                        // background-color: my-color(grey-light-100),
                        border-color: my-color(orange-500),
                        box-shadow: (0 0 0 2px my-color(grey-light-100), 0 0 0 4px my-color(blue-600))
                    ),
                    required: (
                        selector: '+label',
                        pseudo-elements: (
                            after: (
                                content: " *",
                                color: red
                            )
                        )
                    ),
                    disabled: (
                        color: my-color(grey-light-500),
                        border-color: my-color(grey-light-500),
                        // background-color: my-color(grey-light-100)
                    )
                )
            ),

            // BORDER WIDTHS
            bwidth-4: (
                border-width: 4px
            ),
            bwidth-5: (
                border-width: 5px
            ),
            bwidth-6: (
                border-width: 6px
            ),

            // BORDER COLORS
            bcolor-grey-light-100: (
                border-color: my-color(grey-light-100)
            ),
            bcolor-grey-light-200: (
                border-color: my-color(grey-light-200)
            ),
            bcolor-grey-light-300: (
                border-color: my-color(grey-light-300)
            ),
            bcolor-grey-light-400: (
                border-color: my-color(grey-light-400)
            ),
            bcolor-grey-light-500: (
                border-color: my-color(grey-light-500)
            ),
            bcolor-grey-light-600: (
                border-color: my-color(grey-light-600)
            ),
            bcolor-grey-light-700: (
                border-color: my-color(grey-light-700)
            ),
            bcolor-grey-light-800: (
                border-color: my-color(grey-light-800)
            ),
            bcolor-grey-light-900: (
                border-color: my-color(grey-light-900)
            ),
            bcolor-grey-dark-100: (
                border-color: my-color(grey-dark-100)
            ),
            bcolor-grey-dark-200: (
                border-color: my-color(grey-dark-200)
            ),
            bcolor-grey-dark-300: (
                border-color: my-color(grey-dark-300)
            ),
            bcolor-grey-dark-400: (
                border-color: my-color(grey-dark-400)
            ),
            bcolor-grey-dark-500: (
                border-color: my-color(grey-dark-500)
            ),
            bcolor-grey-dark-600: (
                border-color: my-color(grey-dark-600)
            ),
            bcolor-grey-dark-700: (
                border-color: my-color(grey-dark-700)
            ),
            bcolor-grey-dark-800: (
                border-color: my-color(grey-dark-800)
            ),
            bcolor-grey-dark-900: (
                border-color: my-color(grey-dark-900)
            ),
            bcolor-lime-100: (
                border-color: my-color(lime-100)
            ),
            bcolor-lime-200: (
                border-color: my-color(lime-200)
            ),
            bcolor-lime-300: (
                border-color: my-color(lime-300)
            ),
            bcolor-lime-400: (
                border-color: my-color(lime-400)
            ),
            bcolor-lime-500: (
                border-color: my-color(lime-500)
            ),
            bcolor-lime-600: (
                border-color: my-color(lime-600)
            ),
            bcolor-lime-700: (
                border-color: my-color(lime-700)
            ),
            bcolor-lime-800: (
                border-color: my-color(lime-800)
            ),
            bcolor-lime-900: (
                border-color: my-color(lime-900)
            ),
            bcolor-orange-100: (
                border-color: my-color(orange-100)
            ),
            bcolor-orange-200: (
                border-color: my-color(orange-200)
            ),
            bcolor-orange-300: (
                border-color: my-color(orange-300)
            ),
            bcolor-orange-400: (
                border-color: my-color(orange-400)
            ),
            bcolor-orange-500: (
                border-color: my-color(orange-500)
            ),
            bcolor-orange-600: (
                border-color: my-color(orange-600)
            ),
            bcolor-orange-700: (
                border-color: my-color(orange-700)
            ),
            bcolor-orange-800: (
                border-color: my-color(orange-800)
            ),
            bcolor-orange-900: (
                border-color: my-color(orange-900)
            ),
            bcolor-purple-100: (
                border-color: my-color(purple-100)
            ),
            bcolor-purple-200: (
                border-color: my-color(purple-200)
            ),
            bcolor-purple-300: (
                border-color: my-color(purple-300)
            ),
            bcolor-purple-400: (
                border-color: my-color(purple-400)
            ),
            bcolor-purple-500: (
                border-color: my-color(purple-500)
            ),
            bcolor-purple-600: (
                border-color: my-color(purple-600)
            ),
            bcolor-purple-700: (
                border-color: my-color(purple-700)
            ),
            bcolor-purple-800: (
                border-color: my-color(purple-800)
            ),
            bcolor-purple-900: (
                border-color: my-color(purple-900)
            ),
            bcolor-blue-100: (
                border-color: my-color(blue-100)
            ),
            bcolor-blue-200: (
                border-color: my-color(blue-200)
            ),
            bcolor-blue-300: (
                border-color: my-color(blue-300)
            ),
            bcolor-blue-400: (
                border-color: my-color(blue-400)
            ),
            bcolor-blue-500: (
                border-color: my-color(blue-500)
            ),
            bcolor-blue-600: (
                border-color: my-color(blue-600)
            ),
            bcolor-blue-700: (
                border-color: my-color(blue-700)
            ),
            bcolor-blue-800: (
                border-color: my-color(blue-800)
            ),
            bcolor-blue-900: (
                border-color: my-color(blue-900)
            ),
            bcolor-red-100: (
                border-color: my-color(red-100)
            ),
            bcolor-red-200: (
                border-color: my-color(red-200)
            ),
            bcolor-red-300: (
                border-color: my-color(red-300)
            ),
            bcolor-red-400: (
                border-color: my-color(red-400)
            ),
            bcolor-red-500: (
                border-color: my-color(red-500)
            ),
            bcolor-red-600: (
                border-color: my-color(red-600)
            ),
            bcolor-red-700: (
                border-color: my-color(red-700)
            ),
            bcolor-red-800: (
                border-color: my-color(red-800)
            ),
            bcolor-red-900: (
                border-color: my-color(red-900)
            ),
            bcolor-green-100: (
                border-color: my-color(green-100)
            ),
            bcolor-green-200: (
                border-color: my-color(green-200)
            ),
            bcolor-green-300: (
                border-color: my-color(green-300)
            ),
            bcolor-green-400: (
                border-color: my-color(green-400)
            ),
            bcolor-green-500: (
                border-color: my-color(green-500)
            ),
            bcolor-green-600: (
                border-color: my-color(green-600)
            ),
            bcolor-green-700: (
                border-color: my-color(green-700)
            ),
            bcolor-green-800: (
                border-color: my-color(green-800)
            ),
            bcolor-green-900: (
                border-color: my-color(green-900)
            ),

            // c-skin m-checkbox-1
            checkbox-1: (
                pseudo-elements: (
                    before: (
                        selector: '+*',
                        content: '',
                        border-width: 1px,
                        border-style: solid,
                        border-color: my-color(grey-light-800),
                        background-color: my-color(grey-light-100),
                        border-radius: my-border-radius(1)
                    )
                ),
                pseudo-classes: (
                    disabled: (
                        selector: '+*',
                        opacity: 0.3
                    ),
                    checked: (
                        selector: '+*',
                        pseudo-elements: (
                            before: (
                                border-color: my-color(orange-500),
                                background: center / 60% no-repeat url('../medias/checkbox_check.svg')
                            )
                        )
                    )
                )
            ),
            // c-skin m-radio-1
            radio-1: (
                pseudo-elements: (
                    before: (
                        selector: '+*',
                        content: '',
                        border-width: 1px,
                        border-style: solid,
                        border-color: my-color(grey-light-800),
                        background: my-color(grey-light-100),
                        border-radius: 100%
                    )
                ),
                pseudo-classes: (
                    disabled: (
                        selector: '+*',
                        opacity: 0.3
                    ),
                    checked: (
                        selector: '+*',
                        pseudo-elements: (
                            before: (
                                border-color: my-color(orange-500),
                                background: radial-gradient(circle, my-color(orange-500) 37%, my-color(grey-light-100) 42%, my-color(grey-light-100) 100%)
                            )
                        )
                    )
                )
            ),
            // c-skin m-input-badge-1
            input-badge-1: (
                pseudo-classes: (
                    disabled: (
                        selector: '+*',
                        opacity: 0.3
                    ),
                    checked: (
                        selector: '+*',
                        color: my-color(orange-500),
                        border-color: my-color(orange-500)
                    )
                )
            ),
            input-badge-1-ext-1: (
                pseudo-classes: (
                    checked: (
                        selector: '+*+*',
                        color: my-color(orange-500),
                        border-color: my-color(orange-500)
                    )
                )
            ),
            // c-skin m-disabled
            disabled: (
                pointer-events: none,
                color: grey,
                background-color: lightgrey
            ),
            // COLORS
            c-orange-100: (
                color: my-color(orange-100)
            ),
            c-orange-200: (
                color: my-color(orange-200)
            ),
            c-orange-300: (
                color: my-color(orange-300)
            ),
            c-orange-400: (
                color: my-color(orange-400)
            ),
            c-orange-500: (
                color: my-color(orange-500)
            ),
            c-orange-600: (
                color: my-color(orange-600)
            ),
            c-orange-700: (
                color: my-color(orange-700)
            ),
            c-orange-800: (
                color: my-color(orange-800)
            ),
            c-orange-900: (
                color: my-color(orange-900)
            ),
            c-red-100: (
                color: my-color(red-100)
            ),
            c-red-200: (
                color: my-color(red-200)
            ),
            c-red-300: (
                color: my-color(red-300)
            ),
            c-red-400: (
                color: my-color(red-400)
            ),
            c-red-500: (
                color: my-color(red-500)
            ),
            c-red-600: (
                color: my-color(red-600)
            ),
            c-red-700: (
                color: my-color(red-700)
            ),
            c-red-800: (
                color: my-color(red-800)
            ),
            c-red-900: (
                color: my-color(red-900)
            ),
            c-lime-100: (
                color: my-color(lime-100)
            ),
            c-lime-200: (
                color: my-color(lime-200)
            ),
            c-lime-300: (
                color: my-color(lime-300)
            ),
            c-lime-400: (
                color: my-color(lime-400)
            ),
            c-lime-500: (
                color: my-color(lime-500)
            ),
            c-lime-600: (
                color: my-color(lime-600)
            ),
            c-lime-700: (
                color: my-color(lime-700)
            ),
            c-lime-800: (
                color: my-color(lime-800)
            ),
            c-lime-900: (
                color: my-color(lime-900)
            ),
            c-green-100: (
                color: my-color(green-100)
            ),
            c-green-200: (
                color: my-color(green-200)
            ),
            c-green-300: (
                color: my-color(green-300)
            ),
            c-green-400: (
                color: my-color(green-400)
            ),
            c-green-500: (
                color: my-color(green-500)
            ),
            c-green-600: (
                color: my-color(green-600)
            ),
            c-green-700: (
                color: my-color(green-700)
            ),
            c-green-800: (
                color: my-color(green-800)
            ),
            c-green-900: (
                color: my-color(green-900)
            ),
            c-blue-100: (
                color: my-color(blue-100)
            ),
            c-blue-200: (
                color: my-color(blue-200)
            ),
            c-blue-300: (
                color: my-color(blue-300)
            ),
            c-blue-400: (
                color: my-color(blue-400)
            ),
            c-blue-500: (
                color: my-color(blue-500)
            ),
            c-blue-600: (
                color: my-color(blue-600)
            ),
            c-blue-700: (
                color: my-color(blue-700)
            ),
            c-blue-800: (
                color: my-color(blue-800)
            ),
            c-blue-900: (
                color: my-color(blue-900)
            ),
            c-purple-100: (
                color: my-color(purple-100)
            ),
            c-purple-200: (
                color: my-color(purple-200)
            ),
            c-purple-300: (
                color: my-color(purple-300)
            ),
            c-purple-400: (
                color: my-color(purple-400)
            ),
            c-purple-500: (
                color: my-color(purple-500)
            ),
            c-purple-600: (
                color: my-color(purple-600)
            ),
            c-purple-700: (
                color: my-color(purple-700)
            ),
            c-purple-800: (
                color: my-color(purple-800)
            ),
            c-purple-900: (
                color: my-color(purple-900)
            ),
            c-grey-light-100: (
                color: my-color(grey-light-100)
            ),
            c-grey-light-200: (
                color: my-color(grey-light-200)
            ),
            c-grey-light-300: (
                color: my-color(grey-light-300)
            ),
            c-grey-light-400: (
                color: my-color(grey-light-400)
            ),
            c-grey-light-500: (
                color: my-color(grey-light-500)
            ),
            c-grey-light-600: (
                color: my-color(grey-light-600)
            ),
            c-grey-light-700: (
                color: my-color(grey-light-700)
            ),
            c-grey-light-800: (
                color: my-color(grey-light-800)
            ),
            c-grey-light-900: (
                color: my-color(grey-light-900)
            ),
            c-grey-dark-100: (
                color: my-color(grey-dark-100)
            ),
            c-grey-dark-200: (
                color: my-color(grey-dark-200)
            ),
            c-grey-dark-300: (
                color: my-color(grey-dark-300)
            ),
            c-grey-dark-400: (
                color: my-color(grey-dark-400)
            ),
            c-grey-dark-500: (
                color: my-color(grey-dark-500)
            ),
            c-grey-dark-600: (
                color: my-color(grey-dark-600)
            ),
            c-grey-dark-700: (
                color: my-color(grey-dark-700)
            ),
            c-grey-dark-800: (
                color: my-color(grey-dark-800)
            ),
            c-grey-dark-900: (
                color: my-color(grey-dark-900)
            ),
            // BACKGROUND COLOR
            bc-orange-100: (
                background-color: my-color(orange-100)
            ),
            bc-orange-200: (
                background-color: my-color(orange-200)
            ),
            bc-orange-300: (
                background-color: my-color(orange-300)
            ),
            bc-orange-400: (
                background-color: my-color(orange-400)
            ),
            bc-orange-500: (
                background-color: my-color(orange-500)
            ),
            bc-orange-600: (
                background-color: my-color(orange-600)
            ),
            bc-orange-700: (
                background-color: my-color(orange-700)
            ),
            bc-orange-800: (
                background-color: my-color(orange-800)
            ),
            bc-orange-900: (
                background-color: my-color(orange-900)
            ),
            bc-red-100: (
                background-color: my-color(red-100)
            ),
            bc-red-200: (
                background-color: my-color(red-200)
            ),
            bc-red-300: (
                background-color: my-color(red-300)
            ),
            bc-red-400: (
                background-color: my-color(red-400)
            ),
            bc-red-500: (
                background-color: my-color(red-500)
            ),
            bc-red-600: (
                background-color: my-color(red-600)
            ),
            bc-red-700: (
                background-color: my-color(red-700)
            ),
            bc-red-800: (
                background-color: my-color(red-800)
            ),
            bc-red-900: (
                background-color: my-color(red-900)
            ),
            bc-lime-100: (
                background-color: my-color(lime-100)
            ),
            bc-lime-200: (
                background-color: my-color(lime-200)
            ),
            bc-lime-300: (
                background-color: my-color(lime-300)
            ),
            bc-lime-400: (
                background-color: my-color(lime-400)
            ),
            bc-lime-500: (
                background-color: my-color(lime-500)
            ),
            bc-lime-600: (
                background-color: my-color(lime-600)
            ),
            bc-lime-700: (
                background-color: my-color(lime-700)
            ),
            bc-lime-800: (
                background-color: my-color(lime-800)
            ),
            bc-lime-900: (
                background-color: my-color(lime-900)
            ),
            bc-green-100: (
                background-color: my-color(green-100)
            ),
            bc-green-200: (
                background-color: my-color(green-200)
            ),
            bc-green-300: (
                background-color: my-color(green-300)
            ),
            bc-green-400: (
                background-color: my-color(green-400)
            ),
            bc-green-500: (
                background-color: my-color(green-500)
            ),
            bc-green-600: (
                background-color: my-color(green-600)
            ),
            bc-green-700: (
                background-color: my-color(green-700)
            ),
            bc-green-800: (
                background-color: my-color(green-800)
            ),
            bc-green-900: (
                background-color: my-color(green-900)
            ),
            bc-blue-100: (
                background-color: my-color(blue-100)
            ),
            bc-blue-200: (
                background-color: my-color(blue-200)
            ),
            bc-blue-300: (
                background-color: my-color(blue-300)
            ),
            bc-blue-400: (
                background-color: my-color(blue-400)
            ),
            bc-blue-500: (
                background-color: my-color(blue-500)
            ),
            bc-blue-600: (
                background-color: my-color(blue-600)
            ),
            bc-blue-700: (
                background-color: my-color(blue-700)
            ),
            bc-blue-800: (
                background-color: my-color(blue-800)
            ),
            bc-blue-900: (
                background-color: my-color(blue-900)
            ),
            bc-purple-100: (
                background-color: my-color(purple-100)
            ),
            bc-purple-200: (
                background-color: my-color(purple-200)
            ),
            bc-purple-300: (
                background-color: my-color(purple-300)
            ),
            bc-purple-400: (
                background-color: my-color(purple-400)
            ),
            bc-purple-500: (
                background-color: my-color(purple-500)
            ),
            bc-purple-600: (
                background-color: my-color(purple-600)
            ),
            bc-purple-700: (
                background-color: my-color(purple-700)
            ),
            bc-purple-800: (
                background-color: my-color(purple-800)
            ),
            bc-purple-900: (
                background-color: my-color(purple-900)
            ),
            bc-grey-light-100: (
                background-color: my-color(grey-light-100)
            ),
            bc-grey-light-200: (
                background-color: my-color(grey-light-200)
            ),
            bc-grey-light-300: (
                background-color: my-color(grey-light-300)
            ),
            bc-grey-light-400: (
                background-color: my-color(grey-light-400)
            ),
            bc-grey-light-500: (
                background-color: my-color(grey-light-500)
            ),
            bc-grey-light-600: (
                background-color: my-color(grey-light-600)
            ),
            bc-grey-light-700: (
                background-color: my-color(grey-light-700)
            ),
            bc-grey-light-800: (
                background-color: my-color(grey-light-800)
            ),
            bc-grey-light-900: (
                background-color: my-color(grey-light-900)
            ),
            bc-grey-dark-100: (
                background-color: my-color(grey-dark-100)
            ),
            bc-grey-dark-200: (
                background-color: my-color(grey-dark-200)
            ),
            bc-grey-dark-300: (
                background-color: my-color(grey-dark-300)
            ),
            bc-grey-dark-400: (
                background-color: my-color(grey-dark-400)
            ),
            bc-grey-dark-500: (
                background-color: my-color(grey-dark-500)
            ),
            bc-grey-dark-600: (
                background-color: my-color(grey-dark-600)
            ),
            bc-grey-dark-700: (
                background-color: my-color(grey-dark-700)
            ),
            bc-grey-dark-800: (
                background-color: my-color(grey-dark-800)
            ),
            bc-grey-dark-900: (
                background-color: my-color(grey-dark-900)
            ),
            brad-1: (
                border-radius: my-border-radius(1)
            ),
            brad-2: (
                border-radius: my-border-radius(2)
            ),
            brad-3: (
                border-radius: my-border-radius(3)
            ),
            brad-4: (
                border-radius: my-border-radius(4)
            ),
            brad-5: (
                border-radius: my-border-radius(5)
            ),
            brad-6: (
                border-radius: my-border-radius(6)
            ),
            brad-7: (
                border-radius: my-border-radius(7)
            ),
            brad-8: (
                border-radius: my-border-radius(8)
            ),
            brad-9: (
                border-radius: my-border-radius(9)
            ),
            brad-10: (
                border-radius: my-border-radius(10)
            ),
            bradtl-1: (
                border-top-left-radius: my-border-radius(1)
            ),
            bradtl-2: (
                border-top-left-radius: my-border-radius(2)
            ),
            bradtl-3: (
                border-top-left-radius: my-border-radius(3)
            ),
            bradtl-4: (
                border-top-left-radius: my-border-radius(4)
            ),
            bradtl-5: (
                border-top-left-radius: my-border-radius(5)
            ),
            bradtl-6: (
                border-top-left-radius: my-border-radius(6)
            ),
            bradtl-7: (
                border-top-left-radius: my-border-radius(7)
            ),
            bradtl-8: (
                border-top-left-radius: my-border-radius(8)
            ),
            bradtl-9: (
                border-top-left-radius: my-border-radius(9)
            ),
            bradtl-10: (
                border-top-left-radius: my-border-radius(10)
            ),
            bradtr-1: (
                border-top-right-radius: my-border-radius(1)
            ),
            bradtr-2: (
                border-top-right-radius: my-border-radius(2)
            ),
            bradtr-3: (
                border-top-right-radius: my-border-radius(3)
            ),
            bradtr-4: (
                border-top-right-radius: my-border-radius(4)
            ),
            bradtr-5: (
                border-top-right-radius: my-border-radius(5)
            ),
            bradtr-6: (
                border-top-right-radius: my-border-radius(6)
            ),
            bradtr-7: (
                border-top-right-radius: my-border-radius(7)
            ),
            bradtr-8: (
                border-top-right-radius: my-border-radius(8)
            ),
            bradtr-9: (
                border-top-right-radius: my-border-radius(9)
            ),
            bradtr-10: (
                border-top-right-radius: my-border-radius(10)
            ),
            bradbr-1: (
                border-bottom-right-radius: my-border-radius(1)
            ),
            bradbr-2: (
                border-bottom-right-radius: my-border-radius(2)
            ),
            bradbr-3: (
                border-bottom-right-radius: my-border-radius(3)
            ),
            bradbr-4: (
                border-bottom-right-radius: my-border-radius(4)
            ),
            bradbr-5: (
                border-bottom-right-radius: my-border-radius(5)
            ),
            bradbr-6: (
                border-bottom-right-radius: my-border-radius(6)
            ),
            bradbr-7: (
                border-bottom-right-radius: my-border-radius(7)
            ),
            bradbr-8: (
                border-bottom-right-radius: my-border-radius(8)
            ),
            bradbr-9: (
                border-bottom-right-radius: my-border-radius(9)
            ),
            bradbr-10: (
                border-bottom-right-radius: my-border-radius(10)
            ),
            bradbl-1: (
                border-bottom-left-radius: my-border-radius(1)
            ),
            bradbl-2: (
                border-bottom-left-radius: my-border-radius(2)
            ),
            bradbl-3: (
                border-bottom-left-radius: my-border-radius(3)
            ),
            bradbl-4: (
                border-bottom-left-radius: my-border-radius(4)
            ),
            bradbl-5: (
                border-bottom-left-radius: my-border-radius(5)
            ),
            bradbl-6: (
                border-bottom-left-radius: my-border-radius(6)
            ),
            bradbl-7: (
                border-bottom-left-radius: my-border-radius(7)
            ),
            bradbl-8: (
                border-bottom-left-radius: my-border-radius(8)
            ),
            bradbl-9: (
                border-bottom-left-radius: my-border-radius(9)
            ),
            bradbl-10: (
                border-bottom-left-radius: my-border-radius(10)
            ),
            // BOX SHADOWS
            bs-1: (
                box-shadow: my-shadow(1)
            ),
            bs-2: (
                box-shadow: my-shadow(2)
            )
        )
    )
);